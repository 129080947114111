import React, { useState } from "react";
import TimeAgo from "react-timeago";
import { format } from "date-fns";
import {
  Collapse,
  IconButton,
  Paper,
  Button,
  Grid,
  Avatar,
  Tooltip,
  Box,
  Typography,
  Rating,
} from "@mui/material";
import ConfirmDialog from "../../../ConfirmDialog";
import { useTheme } from "@mui/material/styles";
import frenchStrings from "react-timeago/lib/language-strings/fr";
import enStrings from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";

import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../useApi";
import { retrieveError } from "../../../utilities/constants";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ReactPlayer from "react-player/lazy";
import ImageGallery from "react-image-gallery";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Trans, useTranslation } from "react-i18next";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Notification from "../../../Notification";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--bs-primary)",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor:
      theme.palette.mode === "light" ? "#e5e5e5" : theme.palette.grey[800],
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AnswerRow = ({ answer }) => {
  const { t, i18n } = useTranslation();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const formatter = buildFormatter(frenchStrings);
  const formatterBis = buildFormatter(enStrings);
  const [open, setOpen] = React.useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleClick = () => {
    setOpen(!open);
  };

  const formatImages = (images) => {
    let formattedImages = [];
    images.forEach((element) => {
      formattedImages.push({
        original: element.image,
        thumbnail: element.image,
        originalHeight: "360",
      });
    });
    return formattedImages;
  };

  const rateUser = (valueRating, userId) => {
    mutationRateUser.mutate({
      rate: valueRating,
      user: userId,
    });
  };

  const mutationRateUser = useMutation({
    mutationFn: (rating) => {
      return axiosInstance.post(
        "https://api-beta.seeks.biz/socialmedia/rating/",
        rating
      );
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
      console.log("Erreur lors de la soumission du formulaire : ", error);
    },
    onSuccess: (data, variables, context) => {
      setNotify({
        isOpen: true,
        message: t("Rating posted successfully!"),
        type: "success",
      });
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    },
  });
  return (
    <>
      <StyledTableRow key={answer.text} width="1%">
        <Tooltip title={t("See author details and attachment(s)")}>
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleClick}
            >
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </StyledTableCell>
        </Tooltip>
        <StyledTableCell component="th" scope="row">
          <div style={{ display: "flex", alignItems: "center" }}>
            {answer.author?.avatar !== null ? (
              <div style={{ marginRight: "8px" }}>
                <Avatar src={answer.author?.avatar}></Avatar>
              </div>
            ) : null}
            <div>
              {answer.author?.first_name} {answer.author?.last_name} (
              {answer.author?.company_name})
            </div>
          </div>
        </StyledTableCell>
        <StyledTableCell>{answer.text}</StyledTableCell>
        <StyledTableCell align="right">
          <a href={answer.link} target="_blank">
            {answer.link}
          </a>
        </StyledTableCell>
        <StyledTableCell align="right">
          {answer.quotation || answer.price == null ? (
            <Trans>Price on quote</Trans>
          ) : parseFloat(answer.price?.split(".")[1]) % 1 !== 0 ? (
            parseFloat(answer.price) + "€"
          ) : (
            parseFloat(answer.price) + "€"
          )}
        </StyledTableCell>
        <StyledTableCell align="right">
          <TimeAgo
            date={answer.created_at}
            formatter={
              localStorage.getItem("i18next") === "fr"
                ? formatterBis
                : formatter
            }
          />
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* <Grid item xs={12}> */}
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                <Trans>Author's details</Trans>
              </h5>
            </div>

            <br />
            {/* </Grid> */}
            <Grid container spacing={2}>
              <Grid
                item
                lg={3}
                md={6}
                xs={12}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Trans>E-mail</Trans>:{" "}
                <a href={`mailto:${answer?.author?.email}`}>
                  {answer?.author?.email}
                </a>
              </Grid>
              <Grid
                item
                lg={3}
                md={6}
                xs={12}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Trans>Job title</Trans>: {answer?.author?.position}
              </Grid>
              <Grid
                item
                lg={3}
                md={6}
                xs={12}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Trans>Seniority in position</Trans>:{" "}
                {format(new Date(answer?.author?.job_seniority), "dd-MM-yyyy")}
              </Grid>
              <Grid
                item
                lg={3}
                md={6}
                xs={12}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Trans>Average rating</Trans>:{" "}
                {answer?.author?.avg_rating &&
                answer?.author?.avg_rating !== null ? (
                  <>
                    {/* <Typography component="legend">
                      {answer?.author?.avg_rating}
                    </Typography> */}
                    <Tooltip
                      title={
                        t("This user is rated ") +
                        answer?.author?.avg_rating +
                        t(
                          " for his past answers. Click on the number of stars you want to rate him for this answer to your post."
                        )
                      }
                    >
                      <Rating
                        value={answer?.author?.avg_rating}
                        size="small"
                        onChange={(event, newValue) => {
                          setConfirmDialog({
                            isOpen: true,
                            title:
                              t("Are you sure you want to rate this user ") +
                              newValue +
                              t(" stars?"),
                            subTitle: "You can't change your rating later.",
                            onConfirm: () => {
                              rateUser(newValue, answer?.author?.id);
                            },
                          });
                        }}
                        precision={1}
                      />
                    </Tooltip>
                  </>
                ) : (
                  <div>
                    {/* <Typography component="legend">
                      <Trans>No rating</Trans>
                    </Typography> */}
                    <Tooltip
                      title={t(
                        "This user has no rating yet. Click on the number of stars you want to rate him for this answer to your post."
                      )}
                    >
                      <Rating
                        name="no-value"
                        value={null}
                        size="small"
                        precision={1}
                        onChange={(event, newValue) => {
                          setConfirmDialog({
                            isOpen: true,
                            title:
                              t("Are you sure you want to rate this user ") +
                              newValue +
                              t(" stars?"),
                            subTitle: "You can't change your rating later.",
                            onConfirm: () => {
                              rateUser(newValue, answer?.author?.id);
                            },
                          });
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
              </Grid>
            </Grid>
            <br />
            {answer.images &&
            answer.images.length == 0 &&
            answer.attached_files &&
            answer.attached_files.length == 0 ? (
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Trans>No image nor attached file</Trans>
              </div>
            ) : (
              <Grid container>
                <Grid item xs={12}>
                  {answer.images && answer.images.length > 0 ? (
                    <ImageGallery
                      items={formatImages(answer.images)}
                      showPlayButton={false}
                      thumbnailPosition="right"
                      useBrowserFullscreen={false}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {answer.attached_files &&
                    answer.attached_files.length > 0 &&
                    answer.attached_files.find(
                      (elt) => !elt.attached?.endsWith(".pdf")
                    )
                      ? answer.attached_files
                          .filter((elt) => !elt.attached?.endsWith(".pdf"))
                          .map((video) => (
                            <Grid item xl={4} md={6} xs={12}>
                              <ReactPlayer
                                url={video.attached}
                                controls={true}
                                width="100%"
                                height="100%"
                              />
                            </Grid>
                          ))
                      : null}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {answer.attached_files &&
                    answer.attached_files.length > 0 &&
                    answer.attached_files.find((elt) =>
                      elt.attached?.endsWith(".pdf")
                    )
                      ? answer.attached_files
                          .filter((elt) => elt.attached?.endsWith(".pdf"))
                          .map((file) => (
                            <Grid item key={file.id}>
                              <a
                                href={file.attached}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Box
                                  elevation={3}
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <PictureAsPdfIcon
                                    color="error"
                                    sx={{ fontSize: 64 }}
                                  />
                                  <Tooltip
                                    title={
                                      file.attached.split(
                                        "/media/answers/attached/"
                                      )[1]
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        maxWidth: 100,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {
                                        file.attached.split(
                                          "/media/answers/attached/"
                                        )[1]
                                      }
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </a>
                            </Grid>
                          ))
                      : null}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

const MyPostAnswers = (props) => {
  const { answers } = props;
  const { t, i18n } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="comment-area" style={{ marginTop: "24px" }}>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="like-block position-relative d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div className="total-like-block ms-2 me-3">
                {answers && answers.length ? answers.length : 0}{" "}
                {answers &&
                (!answers.length ||
                  answers.length === 0 ||
                  answers.length === 1) ? (
                  <Trans>answer</Trans>
                ) : (
                  <Trans>answers</Trans>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center feather-icon mt-2 mt-md-0">
            <Button onClick={() => handleClick()}>
              <QuestionAnswerIcon />
              <Trans>See answers</Trans>
            </Button>
          </div>
        </div>
      </div>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {answers && answers.length <= 0 ? (
              <div style={{ textAlign: "center", fontSize: "18px" }}>
                <Trans>No answer yet</Trans>
              </div>
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width="1%"></StyledTableCell>
                      <StyledTableCell>
                        <Trans>Author</Trans>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Trans>Content</Trans>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Trans>Link</Trans>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Trans>Price</Trans>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Trans>Date and time</Trans>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {answers &&
                      answers.map((answer) => (
                        <AnswerRow key={answer.id} answer={answer} />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default MyPostAnswers;
